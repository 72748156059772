import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import PartnersInfoList from "../components/Common/PartnersInfoList";
import '/src/assets/css/Partners/partners-intro.css'
import {Link} from "gatsby"
import SEO from "../components/App/SEO";

import partnersHero from "../assets/images/partners/partners-hero.png"
import TwoCartLogoImg from "../assets/images/partners/2-cart-logo.png"
import ContentLogoImg from "../assets/images/partners/logo-content-online.png"
import TheConLogoImg from "../assets/images/partners/logo_thecon.png"
import XsureLogoImg from "../assets/images/partners/xsure_logo-small.png"
import prestaLogoImg from "../assets/images/partners/prestalabs-logo.png"
import modelLogoImg from "../assets/images/partners/modeltheme-logo.png"


const partnersList = [
    {
        logo: TheConLogoImg,
        logoAlt: 'Thecon',
        partnerDescription: 'Even if you need to create a presentation site, an online store or you are interested in solutions for the development of a mobile application, or a performant ERP/CRM, the Thecon.ro partners can help you with software solutions made by experienced developers.',
        email: 'contact@thecon.ro',
        phone: '+40 756 192 121',
        website: 'https://thecon.ro',
    },
    {
        logo: TwoCartLogoImg,
        logoAlt: '2 Cart',
        partnerDescription: 'The SaaS platform that is developed by our 2Cart partners is the perfect solution to launch your own online store with just a few clicks, benefiting from all the advantages of the PWA technology: fast loading site, progressive improvements and many more.',
        email: 'contact@2cart.io',
        phone: '+40 740 47 00 48',
        website: 'https://2cart.io'
    },
    {
        logo: modelLogoImg,
        logoAlt: 'ModelTheme',
        partnerDescription: 'All successful projects developed in WordPress are beginning with the Modeltheme partner, author of Elite ENVATO, which since 2006 has specialized in making premium themes for different types of websites, with the right wide range of businesses.',
        email: 'sales@modeltheme.com',
        phone: '+40 748 351 305',
        website: 'https://modeltheme.com',
    },
    {
        logo: prestaLogoImg,
        logoAlt: 'Presta Maniacs',
        partnerDescription: 'Your online store that is developed in PrestaShop will be more efficient if you will use the modules developed by the Presta Maniacs partner. Find out which of the certified PrestaShop modules are suited to you and don\'t hesitate to reach out for more information.',
        email: 'contact@prestamaniacs.com',
        phone: '+40 757 383 844',
        website: 'https://prestamaniacs.com',
    },
    {
        logo: XsureLogoImg,
        logoAlt: 'XSure',
        partnerDescription: 'Starting with software development products to complete digital transformations, the mission Xsure has is to befriend technology with any business sector. Xsure professionals are ready to deliver IT solutions that are designed to the highest standards.',
        email: 'outsource@xsure.io',
        phone: '+44 1223 92 6506',
        website: 'https://xsure.io',
    },
    {
        logo: ContentLogoImg,
        logoAlt: 'Content Online',
        partnerDescription: 'ContentOnline is creating content for online stores and is promoting online businesses from any kind of domain. If you are searching for complete digital marketing services, including content creation of strategic foto&video, reach out to our partners.',
        email: 'contact@contentonline.ro',
        phone: '+40 732 731 217',
        website: 'https://contentonline.ro',
    }

]

const Partners = () => {

    return (
        <Layout>
            {/*SEO*/}
            <SEO title="Discover what Sitebunker.net partners can help you scale online."
                 description="The right partnerships can be essential when you want to grow your business. Find out here the list of Sitebunker.net recommended partners with whom you can solve any challenge regarding your online store and not only."
            />

            {/*Navbar*/}
            <Navbar/>

            {/*Hero Section*/}
            <section id='partners-intro'>
                <div className="container">
                    <div className="row position-relative">
                        <div className="col-md-7 col-12 z-index-pos">
                            <h2 className='h2-title mb-4'>Sitebunker.net partners</h2>
                            <h3 className='mb-2'>Discover Sitebunker.net partners list with which your online business will scale.</h3>
                            <p className='mb-5'>We are building trust and performance to support the growth of any kind of online business. You can join Sitebunker.net partners and let's prove together how important it is to choose the services of professionals.</p>
                            <Link className='view-more-button w-50' to='/contact'>Become a partner</Link>
                        </div>
                        <img src={partnersHero} alt="Partner Img" className='partners-hero-img' loading="lazy"/>
                    </div>
                </div>
            </section>

            {/*Partners List*/}
            <PartnersInfoList partnerData={partnersList}/>

            {/*Footer*/}
            <Footer/>
        </Layout>
    );
}

export default Partners;
